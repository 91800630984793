import uuid from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert = (
  msg,
  alertType,
  defaultTimeout = 5000,
  loading = false
) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: {
      msg,
      alertType,
      id
    },
    loading
  });

  setTimeout(
    () => dispatch({ type: REMOVE_ALERT, payload: id, loading }),
    defaultTimeout
  );
};
