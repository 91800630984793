// RACFP
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getJsonPosts } from '../../actions/json';
import queryString from 'query-string';
import Spinner from '../layout/Spinner';
import JsonItem from '../json/JsonItem';
import { loginCrud } from '../../actions/auth';
import { addPost, deletePost, getPosts } from '../../actions/post';
import { isRestElement } from '@babel/types';
import { Link, withRouter, Redirect } from 'react-router-dom';

const DeletePass = ({
  getJsonPosts,
  json: { json, loading },
  addPost,
  match,
  deletePost,
  getPosts,
  post: { posts }
}) => {
  let userId = match.params.userId;
  // console.log(userId);
  //   let url = this.props.location.search;
  let params = queryString.parse(userId);
  useEffect(() => {
    loginCrud(params.id, params.type);
    getJsonPosts();

    getPosts();
  }, [getJsonPosts, getPosts]);

  const deleteAllPostsFromWordpress = () => {
    // deletePost(id);

    posts.map((post, index) => {
      // console.log(post.length++);
      // console.log('delete' + index + 1);
      deletePost(post.id);

      getPosts();
    });
  };
  const addAllPostsToWordpress = () => {
    if (loading || json === []) {
    } else {
      json.map(item => {
        // console.log(item);
        const { VILLE_OFFRE, NO_ASP, TEXTE_FR, CATEGORIE, LOYER, PRIX } = item;

        const formDataSent = {
          content: TEXTE_FR,
          title: CATEGORIE + ' - ' + VILLE_OFFRE + ' - ',
          status: 'publish',
          fields: {
            NO_ASP: NO_ASP,
            CATEGORIE: CATEGORIE,
            LOYER: LOYER,
            PRIX: PRIX
          }
        };

        addPost(formDataSent);
      });
      getPosts();
    }
  };

  if (loading || json === []) {
    return <Spinner />;
  } else {
    return (
      <Fragment>
        <button
          type="button"
          onClick={addAllPostsToWordpress}
          className="btn btn-primary"
        >
          <i className="fas fa-plus"></i>
          <span> Add ALL posts to wordpress</span>
        </button>
        <button
          type="button"
          onClick={deleteAllPostsFromWordpress}
          className="btn btn-danger"
        >
          <i className="fas fa-plus"></i>
          <span> Delete ALL posts from wordpress</span>
        </button>

        {json.map((item, index) => {
          // console.log(item.length++);
          return (
            <Fragment>
              <p>Items: {index}</p>
              <JsonItem key={item.NO_ASP} item={item} />
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
};
DeletePass.propTypes = {
  // ptfr
  getJsonPosts: PropTypes.func.isRequired,
  json: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  addPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  loginCrud: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  json: state.json,
  post: state.post
});

export default connect(
  mapStateToProps,
  { getJsonPosts, addPost, deletePost, getPosts, loginCrud }
)(DeletePass);
