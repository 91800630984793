// racfp
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/profile';
import { Link, withRouter } from 'react-router-dom';

const CreateProfile = ({ createProfile, history }) => {
  const [formData, setFormData] = useState({
    Content: '',
    title: '',
    excerpt: '',
    status: 'publish',
    CODE_CLIENT: '',
    TYPE_OFFRE: '',
    CODE_SOCIETE: '',
    CODE_SITE: '',
    NO_ASP: '',
    NO_DOSSIER: '',
    NO_MANDAT: '',
    TYPE_MANDAT: '',
    DATE_OFFRE: '',
    DATE_MODIF: '',
    DATE_DISPO: '',
    NEGOCIATEUR: '',
    LOYER: '',
    PRIX: '',
    HONORAIRES: '',
    TRAVAUX: '',
    CHARGES: '',
    DEPOT_GARANTIE: '',
    TAXE_HABITATION: '',
    TAXE_FONCIERE: '',
    ADRESSE1_OFFRE: '',
    ADRESSE2_OFFRE: '',
    CP_OFFRE: '',
    VILLE_OFFRE: '',
    CP_INTERNET: '',
    VILLE_INTERNET: '',
    QUARTIER: '',
    RESIDENCE: '',
    TRANSPORT: '',
    PROXIMITE: '',
    SECTEUR: '',
    CATEGORIE: '',
    NB_PIECES: '',
    NB_CHAMBRES: '',
    SURF_HAB: '',
    SURF_CARREZ: '',
    SURF_SEJOUR: '',
    SURF_TERRAIN: '',
    ETAGE: '',
    CODE_ETAGE: '',
    NB_ETAGES: '',
    ANNEE_CONS: '',
    CUISINE: '',
    NB_WC: '',
    NB_SDB: '',
    NB_SE: '',
    NB_PARK_INT: '',
    NB_PARK_EXT: '',
    GARAGE_BOX: '',
    SOUS_SOL: '',
    NB_CAVES: '',
    TYPE_CHAUFF: '',
    NATURE_CHAUFF: '',
    ASCENSEUR: '',
    BALCON: '',
    TERRASSE: '',
    PISCINE: '',
    ACCES_HANDI: '',
    MURS_MITOYENS: '',
    FACADE_TERRAIN: '',
    TEXTE_FR: '',
    TEXTE_MAILING: '',
    PRESTIGE: '',
    INFO_TERRASSE: '',
    INFO_BALCON: '',
    DISPO: '',
    LOYER2: '',
    DATE_LIBRE: '',
    NET_VENDEUR: '',
    HONO_ACQ: '',
    SURF_JARDIN: '',
    COS: '',
    SHON: '',
    DPE_ETIQ1: '',
    DPE_ETIQ2: '',
    DPE_VAL1: '',
    DPE_VAL2: '',
    INFO_KM: '',
    CONTACT: '',
    INFO_CONTACT: '',
    NB_NIVEAUX: '',
    CESSIONDROITAUBAIL: '',
    LONGUEURVITRINE: '',
    INTERPHONE: '',
    MONTECHARGE: '',
    IMMEUBLEINDEPENDANT: '',
    IMMEUBLECOLLECTIF: '',
    IMMEUBLEPRESTIGE: '',
    DIGICODE: '',
    CLIMATISATION: '',
    GARDIENNAGE: '',
    SURFACEPROFESSIONNELLE: '',
    SURFACEANNEXE: '',
    SURFACELOGEMENT: '',
    NO_ASP_LOT: '',
    VIAGER: '',
    NON_ASSUJETTI_DPE: '',
    DPE_VIERGE: '',
    TAUX_HONORAIRES: '',
    COPROPRIETE: '',
    NB_LOTS_COPROPRIETE: '',
    MONTANT_QUOTE_PART: '',
    PROCEDURE_SYNDICAT: '',
    DETAIL_PROCEDURE: ''
  });
  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const {
    Content,
    title,
    excerpt,
    status,
    TYPE_OFFRE,
    CODE_SOCIETE,
    CODE_SITE,
    NO_ASP,
    NO_DOSSIER,
    NO_MANDAT,
    TYPE_MANDAT,
    DATE_OFFRE,
    DATE_MODIF,
    DATE_DISPO,
    NEGOCIATEUR,
    LOYER,
    PRIX,
    HONORAIRES,
    TRAVAUX,
    CHARGES,
    DEPOT_GARANTIE,
    TAXE_HABITATION,
    TAXE_FONCIERE,
    ADRESSE1_OFFRE,
    ADRESSE2_OFFRE,
    CP_OFFRE,
    VILLE_OFFRE,
    CP_INTERNET,
    VILLE_INTERNET,
    QUARTIER,
    RESIDENCE,
    TRANSPORT,
    PROXIMITE,
    SECTEUR,
    CATEGORIE,
    NB_PIECES,
    NB_CHAMBRES,
    SURF_HAB,
    SURF_CARREZ,
    SURF_SEJOUR,
    SURF_TERRAIN,
    ETAGE,
    CODE_ETAGE,
    NB_ETAGES,
    ANNEE_CONS,
    CUISINE,
    NB_WC,
    NB_SDB,
    NB_SE,
    NB_PARK_INT,
    NB_PARK_EXT,
    GARAGE_BOX,
    SOUS_SOL,
    NB_CAVES,
    TYPE_CHAUFF,
    NATURE_CHAUFF,
    ASCENSEUR,
    BALCON,
    TERRASSE,
    PISCINE,
    ACCES_HANDI,
    MURS_MITOYENS,
    FACADE_TERRAIN,
    TEXTE_FR,
    TEXTE_MAILING,
    PRESTIGE,
    INFO_TERRASSE,
    INFO_BALCON,
    DISPO,
    LOYER2,
    DATE_LIBRE,
    NET_VENDEUR,
    HONO_ACQ,
    SURF_JARDIN,
    COS,
    SHON,
    DPE_ETIQ1,
    DPE_ETIQ2,
    DPE_VAL1,
    DPE_VAL2,
    INFO_KM,
    CONTACT,
    INFO_CONTACT,
    NB_NIVEAUX,
    CESSIONDROITAUBAIL,
    LONGUEURVITRINE,
    INTERPHONE,
    MONTECHARGE,
    IMMEUBLEINDEPENDANT,
    IMMEUBLECOLLECTIF,
    IMMEUBLEPRESTIGE,
    DIGICODE,
    CLIMATISATION,
    GARDIENNAGE,
    SURFACEPROFESSIONNELLE,
    SURFACEANNEXE,
    SURFACELOGEMENT,
    NO_ASP_LOT,
    VIAGER,
    NON_ASSUJETTI_DPE,
    DPE_VIERGE,
    TAUX_HONORAIRES,
    COPROPRIETE,
    NB_LOTS_COPROPRIETE,
    MONTANT_QUOTE_PART,
    PROCEDURE_SYNDICAT,
    DETAIL_PROCEDURE
  } = formData;

  const onSubmit = e => {
    e.preventDefault();
    createProfile(formData, history);
  };

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  return (
    <Fragment>
      <h1 className="large text-primary">Create Your Profile</h1>
      <p className="lead">
        <i className="fas fa-user"></i> Let's get some information to make your
        profile stand out
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Content"
            name="Content"
            value={Content}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Content</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
          />
          <small className="form-text">title</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="excerpt"
            name="excerpt"
            value={excerpt}
            onChange={e => onChange(e)}
          />
          <small className="form-text">excerpt</small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="CATEGORIE"
            name="CATEGORIE"
            value={CATEGORIE}
            onChange={e => onChange(e)}
          />
          <small className="form-text">CATEGORIE</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="TYPE_OFFRE"
            name="TYPE_OFFRE"
            value={TYPE_OFFRE}
            onChange={e => onChange(e)}
          />
          <small className="form-text">TYPE_OFFRE</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="NO_ASP"
            name="NO_ASP"
            value={NO_ASP}
            onChange={e => onChange(e)}
          />
          <small className="form-text">NO_ASP</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="LOYER"
            name="LOYER"
            value={LOYER}
            onChange={e => onChange(e)}
          />
          <small className="form-text">LOYER</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="PRIX"
            name="PRIX"
            value={PRIX}
            onChange={e => onChange(e)}
          />
          <small className="form-text">PRIX</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="VILLE_OFFRE"
            name="VILLE_OFFRE"
            value={VILLE_OFFRE}
            onChange={e => onChange(e)}
          />
          <small className="form-text">VILLE_OFFRE</small>
        </div>

        <div className="my-2">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-light"
          >
            Add Social Network Links
          </button>
          <span>Optional</span>
        </div>
        {displaySocialInputs && (
          <Fragment>
            <div className="form-group social-input">
              <i className="fab fa-twitter fa-2x"></i>
              <input type="text" placeholder="Twitter URL" name="twitter" />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-facebook fa-2x"></i>
              <input type="text" placeholder="Facebook URL" name="facebook" />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-youtube fa-2x"></i>
              <input type="text" placeholder="YouTube URL" name="youtube" />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-linkedin fa-2x"></i>
              <input type="text" placeholder="Linkedin URL" name="linkedin" />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-instagram fa-2x"></i>
              <input type="text" placeholder="Instagram URL" name="instagram" />
            </div>
          </Fragment>
        )}

        <input type="submit" className="btn btn-primary my-1" />
        <a className="btn btn-light my-1" href="dashboard.html">
          Go Back
        </a>
      </form>
    </Fragment>
  );
};

CreateProfile.propTypes = {
  // ptfr
  createProfile: PropTypes.func.isRequired
};

// 'withRouter' used to pass history
export default connect(
  null,
  { createProfile }
)(withRouter(CreateProfile));
