export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_CRUD = 'LOGIN_SUCCESS_CRUD';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_ID = 'GET_PROFILE_ID';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_POSTS = 'GET_POSTS';
export const POST_ERROR = 'POST_ERROR';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const GET_POST = 'GET_POST';
export const JSON_GET_POSTS = 'JSON_GET_POSTS';
export const UPDATE_POST = 'UPDATE_POST';
export const READ_IMAGE = 'READ_IMAGE';
