import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { login, logout } from '../../actions/auth';
import { addPost, deletePost, getPosts } from '../../actions/post';
import { getJsonPosts } from '../../actions/json';
import { Link, withRouter, Redirect } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';

const ReadPass = ({
  login,
  match,
  getJsonPosts,
  json: { json },
  logout,
  deletePost,
  getPosts,
  post: { posts, loading }
}) => {
  let userId = match.params.userId;
  // console.log(userId);
  //   let url = this.props.location.search;

  useEffect(() => {
    let params = queryString.parse(userId);
    login(params.id, params.type);
    getPosts();
  }, [login, getPosts]);

  const loginAndLoadData = () => {
    if (localStorage.token) {
      getPosts();
    } else return <Spinner />;
  };

  //   console.log(params.id);
  //   console.log(params.type);
  //

  //http://localhost:3000/crud/id=tatuka&type=Fuckyahoo667

  //   console.log(localStorage.token);
  //   if (localStorage.token) {
  //     setTimeout(console.log('yes token '), 3000);
  //     localStorage.clear();
  //     logout();
  //   } else {
  // login(params.id, params.type);
  //   login(params.id, params.type);

  // login(params.id, params.type);
  // setTimeout(console.log('READY and do Stuff'), 10000);

  console.log('ReadPass.js Stuff done');
  // localStorage.clear();
  // logout();
  //     // return <Redirect to="/login" />;
  //   }

  const deleteAllPostsFromWordpress = () => {
    if (posts !== []) {
      console.log('ReadPass.js posts');
      console.log(posts);

      var n = 0;

      while (n < posts.length) {
        console.log('posts.length= ' + posts.length);
        // debugger;
        if (localStorage.token) {
          deletePost(posts[n].id);
        } else {
          console.log(localStorage);
          console.log('No F token');
        }
        posts = posts.splice(n, 1);
        n++;
      }
    } else {
      return <p>All deleted</p>;
    }
  };

  return (
    <Fragment>
      <div>
        <p>Clearing the DBB</p>

        {deleteAllPostsFromWordpress()}
      </div>
    </Fragment>
  );
};

ReadPass.propTypes = {
  // ptfr
  login: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  // ptb
  isAuthenticated: PropTypes.bool
  //   user_display_name: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  json: state.json,
  post: state.post
});

export default connect(
  mapStateToProps,
  { login, logout, getPosts, deletePost }
)(withRouter(ReadPass));
