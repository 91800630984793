import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Moment from 'react-moment';
import { connect } from 'react-redux';
import { deletePost } from '../../actions/post';
import { setAlert } from '../../actions/alert';
import { addPost } from '../../actions/post';
import Spinner from '../layout/Spinner';

const JsonItem = ({
  setAlert,
  loading,
  addPost,
  item: { VILLE_OFFRE, NO_ASP, TEXTE_FR, CATEGORIE, LOYER, PRIX, CODE_CLIENT }
}) => {
  // console.log('CODE_CLIENT');
  // console.log(CODE_CLIENT.sort());
  const IMAGES = CODE_CLIENT.sort();

  // for (i=0; i <= CODE_CLIENT.length; i++) {

  // }

  // console.log(CODE_CLIENT.length);

  const onPost = () => {
    // const IMAGE1 = `https://articole-smart.eu/web-design-brainiacs.ro/duval/xml/8242-01-${NO_ASP}-a.jpg`;
    const formDataSent = {
      content: TEXTE_FR,
      title: CATEGORIE + ' - ' + VILLE_OFFRE + ' - ',
      status: 'publish',
      fields: {
        VILLE_OFFRE: VILLE_OFFRE,
        NO_ASP: NO_ASP,
        TEXTE_FR: TEXTE_FR,
        CATEGORIE: CATEGORIE,
        LOYER: LOYER,
        PRIX: PRIX,
        IMAGES: IMAGES
      }
    };
    console.log(formDataSent);
    addPost(formDataSent);
  };

  return (
    <div
      className="post bg-white p-1 my-1"
      style={{ backgroundColor: '#eeeeee' }}
    >
      <div>
        <p>
          <b>ID: </b> {NO_ASP}
        </p>
        <p>
          <b>CATEGORIE: </b> {CATEGORIE}
        </p>
        <p>
          <b>VILLE_OFFRE: </b> {VILLE_OFFRE}
        </p>
        <p>
          <b>LOYER: </b> {LOYER}
        </p>
        <p>
          <b>PRIX: </b> {PRIX}
        </p>
        <p>
          {NO_ASP ? (
            <span>
              <b>acf.cantitate_ram:</b>
            </span>
          ) : null}
        </p>

        {NO_ASP ? (
          <img
            className="round-img"
            src={`https://articole-smart.eu/web-design-brainiacs.ro/duval/xml/8242-01-${NO_ASP}-a.jpg`}
            style={{ width: '100px' }}
            alt=""
          />
        ) : null}

        <h4>title</h4>
      </div>
      <div>
        <p className="my-1">
          <b>TEXTE_FR: </b>
          {TEXTE_FR}
        </p>
        <p className="post-date">
          Posted on <Moment format="YYYY/MM/DD">DATE</Moment>
        </p>
        <button type="button" onClick={onPost} className="btn btn-primary">
          <i className="fas fa-plus"></i>
          <span> Add post to wordpress</span>
        </button>
        {/* <button type="button" className="btn btn-light">
          <i className="fas fa-thumbs-down"></i>
        </button>
        <a href="post.html" className="btn btn-primary">
          Discussion <span className="comment-count">3</span>
        </a>
        <button type="button" className="btn btn-danger">
          <i className="fas fa-times"></i>
        </button> */}
      </div>
    </div>
  );
};

JsonItem.propTypes = {
  auth: PropTypes.object.isRequired,
  json: PropTypes.object.isRequired,
  addPost: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  json: state.json,
  post: state.post
});

export default connect(mapStateToProps, { setAlert, addPost })(JsonItem);
