// RACFP
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPosts } from '../../actions/post';
import Spinner from '../layout/Spinner';
import PostItem from './PostItem';
import PostForm from './PostForm';

const Posts = ({ getPosts, post: { posts, loading } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  console.log('Posts.js posts');
  console.log(posts);

  if (loading || posts === undefined || posts === []) {
    return <Spinner />;
  } else {
    // const renderedNow = content.rendered;
    // console.log('renderedNow= ' + renderedNow);
    // console.log('posts after spinner');
    // console.log(posts);
    return (
      <Fragment>
        <h1 className="large text-primary">Posts WP {`: ${posts.length}`}</h1>

        <PostForm />

        <div className="posts">
          {posts.map(post => {
            return <PostItem key={post.id} post={post} />;
          })}
        </div>
      </Fragment>
    );
  }
};

Posts.propTypes = {
  // ptfr
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  post: state.post
});

export default connect(mapStateToProps, { getPosts })(Posts);
