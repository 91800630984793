import { JSON_GET_POSTS } from '../actions/types';

const intialState = {
  BIENS: {},
  BIEN: {},
  loading: true,
  error: {},
  alerts: null
};

export default function(state = intialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JSON_GET_POSTS:
      return {
        ...state,
        json: payload.BIENS.BIEN,
        loading: false
      };

    default:
      return state;
  }
}
