import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_CRUD,
  LOGOUT,
  CLEAR_PROFILE
} from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import serverApi from '../utils/serverAPi';

// LOAD User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    // console.log('loadUser');
    // console.log(localStorage.token);
  }
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`
      }
    };
    const res = await axios.post(
      `${serverApi}/wp-json/jwt-auth/v1/token/validate`,
      config
    );
    // console.log(res);
    dispatch({
      type: USER_LOADED
      // payload: res.code
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Login User Wordpress
export const login = (username, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ username, password });

  try {
    const res = await axios.post(
      `${serverApi}/wp-json/jwt-auth/v1/token`,
      body,
      config
    );
    // console.log(res.data);

    dispatch({
      type: LOGIN_SUCCESS,
      // NOT!!! the token back
      payload: res.data
    });
    // console.log(res.data.user_display_name);
    if (res.data.user_display_name) {
      const responseSuccess = `Welcome, ${res.data.user_display_name}. You're Logged In.`;
      // dispatch(setAlert(responseSuccess, 'success'));
    }

    // dispatch(loadUser());
    // dispatch(setAlert(res.data.msg, 'success'));
  } catch (err) {
    // loop thrrough the errors

    const errors = err.response.data.errors;
    if (errors !== undefined) {
      // errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Login Batch User Wordpress
export const loginCrud = (username, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ username, password });

  try {
    const res = await axios.post(
      `${serverApi}/wp-json/jwt-auth/v1/token`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS_CRUD,
      // NOT!!! the token back
      payload: res.data
    });

    // dispatch(loadUser());
    // dispatch(setAlert(res.data.msg, 'success'));
  } catch (err) {
    // loop thrrough the errors

    const errors = err.response.data.errors;
    if (errors !== undefined) {
      // errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// LOGOUT from Wordpress
export const logout = () => dispatch => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
  // dispatch(setAlert("You're logged out", 'danger'));
};

// Register User Wordpress
export const register = ({
  action,
  display_name,
  user_email,
  user_pass
}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ action, display_name, user_email, user_pass });

  try {
    const res = await axios.post(
      `${serverApi}/?wpwhpro_action=user_email&wpwhpro_api_key=w18calz44khe1bquays7yvio8bhp0dkkqumz0gkmvialfx2vmylxmlwdtalmme3y`,
      body,
      config
    );

    if (res.data.success) {
      dispatch({
        type: REGISTER_SUCCESS,
        // NOT!!! the token back
        payload: res.data
      });

      // dispatch(setAlert(res.data.msg, 'success'));
      //   dispatch(loadUser());
    } else {
      if (res.data.msg) {
        // dispatch(setAlert(res.data.msg, 'danger'));
      }
      if (res.data.data.user_id.errors) {
        if (res.data.data.user_id.errors.existing_user_login) {
          dispatch();
          // setAlert(res.data.data.user_id.errors.existing_user_login, 'danger')
        }
      }

      dispatch({
        type: REGISTER_FAIL
      });
    }
  } catch (err) {
    // loop thrrough the errors

    const errors = err.response.data.errors;
    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: REGISTER_FAIL
    });
  }
};
