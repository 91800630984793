// RACFP
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getJsonPosts } from '../../actions/json';
import Spinner from '../layout/Spinner';
import JsonItem from './JsonItem';
import axios from 'axios';
import { addPost, deletePost, getPosts } from '../../actions/post';
import { isRestElement } from '@babel/types';
import { SearchBox } from './SearchBox';

const Jsons = ({
  getJsonPosts,
  json: { json, loading },
  addPost,
  deletePost,
  getPosts,

  post: { posts }
}) => {
  useEffect(() => {
    getJsonPosts();

    getPosts();
  }, [getJsonPosts, getPosts]);

  const [formData, setFormData] = useState({
    title: ''
  });

  // for (i=0; i <= CODE_CLIENT.length; i++) {

  // }

  // console.log(CODE_CLIENT.length);
  const sortImagesObject = () => {
    return { IMAGE1: 'Image1', IMAGE2: 'Image2', IMAGE3: 'Image3' };
  };

  const deleteAllPostsFromWordpress = () => {
    // deletePost(id);

    posts.map((post, index) => {
      // console.log(post.length++);
      // console.log('delete' + index + 1);
      deletePost(post.id);

      getPosts();
    });
  };
  const addAllPostsToWordpress = () => {
    if (loading || json === []) {
    } else {
      json.map(item => {
        const {
          VILLE_OFFRE,
          NO_ASP,
          TEXTE_FR,
          CATEGORIE,
          LOYER,
          PRIX,
          CODE_CLIENT
        } = item;

        const formDataSent = {
          content: TEXTE_FR,
          title: CATEGORIE + ' - ' + VILLE_OFFRE + ' - ',
          status: 'publish',
          fields: {
            NO_ASP: NO_ASP,
            CATEGORIE: CATEGORIE,
            LOYER: LOYER,
            PRIX: PRIX,
            VILLE_OFFRE: VILLE_OFFRE,
            TEXTE_FR: TEXTE_FR,
            IMAGE1: CODE_CLIENT[0],
            IMAGE2: CODE_CLIENT[1],
            IMAGE3: CODE_CLIENT[2],
            IMAGE4: CODE_CLIENT[3],
            IMAGE5: CODE_CLIENT[4],
            IMAGE6: CODE_CLIENT[5],
            IMAGE7: CODE_CLIENT[6],
            IMAGE8: CODE_CLIENT[7]
          }
        };
        console.log(formDataSent);
        addPost(formDataSent);
      });
      getPosts();
    }
  };

  let searchField = '';

  if (loading || json === []) {
    return <Spinner />;
  } else {
    const { title } = formData;

    const onChange = e =>
      setFormData({ ...formData, [e.target.name]: e.target.value });

    const filteredJson = json.filter(
      item =>
        item.CATEGORIE.toLowerCase().includes(title) ||
        item.NO_ASP.toLowerCase().includes(title)
    );

    return (
      <Fragment>
        <input
          className="search"
          type="search"
          placeholder="search NO_ASP"
          name="title"
          value={title}
          onChange={e => onChange(e)}
        />

        <button
          type="button"
          onClick={addAllPostsToWordpress}
          className="btn btn-primary"
        >
          <i className="fas fa-plus"></i>
          <span> Add ALL posts to wordpress</span>
        </button>
        <button
          type="button"
          onClick={deleteAllPostsFromWordpress}
          className="btn btn-danger"
        >
          <i className="fas fa-plus"></i>
          <span> Delete ALL posts from wordpress</span>
        </button>
        {
          <Fragment>
            <p>Items JSON: {filteredJson.length}</p>
          </Fragment>
        }
        {filteredJson.map((item, index) => {
          return (
            <Fragment key={index}>
              <JsonItem key={item.NO_ASP} item={item} />
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
};
Jsons.propTypes = {
  // ptfr
  getJsonPosts: PropTypes.func.isRequired,
  json: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  addPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  json: state.json,
  post: state.post
});

export default connect(mapStateToProps, {
  getJsonPosts,
  addPost,
  deletePost,
  getPosts
})(Jsons);
