import axios from 'axios';
import { setAlert } from './alert';
import { JSON_GET_POSTS, POST_ERROR } from './types';
import uuid from 'uuid';
// var data = require('http://articole-smart.eu/web-design-brainiacs.ro/duval/duval.json');

// GET Posts
export const getJsonPosts = () => async dispatch => {
  try {
    // const res = await axios.get(
    //   'http://articole-smart.eu/web-design-brainiacs.ro/duval/duval.json',
    //   {},
    //   config
    // );
    delete axios.defaults.headers.common['Authorization'];
    let res = await axios.get(
      'https://articole-smart.eu/web-design-brainiacs.ro/duval/duval_with_images.json',
      null,
      {
        headers: {
          'Content-Type': null
        }
      }
    );
    dispatch({
      type: JSON_GET_POSTS,
      payload: res.data,
      loading: false
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,

      payload: err,
      loading: false
    });
  }
};
