import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { logout } from '../../actions/auth';

const Navbar = ({
  auth: { isAuthenticated, loading, user_display_name },
  logout
}) => {
  const authLinks = (
    <nav className="navbar bg-dark" style={{ backgroundColor: 'green' }}>
      <h1>
        <Link to="/">
          <i className="fas fa-code"></i> JSON Wordpress
        </Link>
      </h1>
      <ul>
        <li>
          <Link to="/json">
            <span>Json</span>
          </Link>
        </li>
        <li>
          <Link to="/posts">
            <span>Posts</span>
          </Link>
        </li>
        <li>
          <Link to="/dashboard">
            <i className="fas fa-user" />{' '}
            <span className="hide-sm">Dashboard</span>
          </Link>
        </li>

        <li>
          <Button onClick={logout}>
            <i className="fas fa-sign-out-alt" />{' '}
            <span className="hide-sm">Logout, {user_display_name}</span>
          </Button>
          {/* <a href={logout}>
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout, {user_display_name}</span>
        </a> */}
        </li>
      </ul>
    </nav>
  );

  const guestLinks = (
    <nav className="navbar bg-dark">
      <h1>
        <Link to="/">
          <i className="fas fa-code"></i> JSON Wordpress
        </Link>
      </h1>
      <ul>
        <li>
          <Link to="profiles.html">Developers</Link>
        </li>
        <li>
          <Link to="/register">Register</Link>
        </li>
        <li>
          <Link to="/login">Login</Link>
        </li>
      </ul>
    </nav>
  );

  return (
    <Fragment>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
    </Fragment>
  );
};

Navbar.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(Navbar);
