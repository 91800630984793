import {
  GET_POSTS,
  POST_ERROR,
  DELETE_POST,
  ADD_POST,
  SET_ALERT,
  REMOVE_ALERT,
  GET_POST,
  UPDATE_POST
} from '../actions/types';

const intialState = {
  posts: [],
  post: null,
  loading: true,
  error: {},
  alerts: null,
  title: ''
};

export default function(state = intialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: payload,
        loading: false
      };
    case GET_POST:
      return {
        posts: payload,
        loading: false
      };
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter(post => post.id !== payload),
        loading: false
      };
    case ADD_POST:
      return {
        ...state,
        posts: [...state.posts, payload],
        loading: false
      };
    case UPDATE_POST:
      return {
        ...state,
        // post: state.post.map(post =>
        //   post.id === payload.id
        //     ? {
        //         ...post,
        //         payload
        //       }
        //     : post
        // ),
        posts: payload,

        error: payload,
        loading: false
      };
    case SET_ALERT:
      console.log('payload= ' + payload);

      return {
        ...state,
        alerts: payload,
        loading: false
      };

    // return [...state, payload];
    case REMOVE_ALERT:
      return {};
    // the payload will be the 'id'
    // return state.filter(post => post._id !== payload);

    default:
      return state;
  }
}
