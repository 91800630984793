import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  GET_PROFILE_ID,
  UPDATE_PROFILE
} from './types';
import serverApi from '../utils/serverAPi';

// GET Current User's Profile
export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await axios.get(`${serverApi}/wp-json/wp/v2/osf_property/`);
    // console.log(res.data);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// GET Current User's Profile
export const getCurrentBien = idWordpress => async dispatch => {
  try {
    const res = await axios.get(
      `${serverApi}/wp-json/wp/v2/osf_property/${idWordpress}`
    );
    console.log(res);
    dispatch({
      type: GET_PROFILE_ID,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add experience
export const addExperience = (formData, history) => async dispatch => {
  try {
  } catch (err) {}
};

// Create a profile
export const createProfile = (
  formData,
  history,
  edit = false
) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(
      `${serverApi}/wp-json/wp/v2/osf_property`,
      formData,
      config
    );
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    // dispatch(setAlert(edit ? 'Profile Updates' : 'Profile created', 'success'));
    if (!edit) {
      history.push('/dashboard');
    }
  } catch (err) {
    // loop thrrough the errors
    console.log(err.message);
    const errors = err.message;
    // if (errors !== undefined) {
    //   errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    // }
    // dispatch(setAlert(err.message, 'danger'));
    dispatch({
      type: PROFILE_ERROR,
      payload: err.message
    });
  }
};

// EDIT a profile
export const editProfile = id => (
  formData,
  history,
  edit = false
) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.patch(
      `${serverApi}/wp-json/wp/v2/osf_property/${id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });
    // dispatch(setAlert(edit ? 'Profile Updates' : 'Profile created', 'success'));
    if (!edit) {
      history.push('/dashboard');
    }
  } catch (err) {
    // loop thrrough the errors
    console.log(err.message);
    const errors = err.message;
    // if (errors !== undefined) {
    //   errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    // }
    // dispatch(setAlert(err.message, 'danger'));
    dispatch({
      type: PROFILE_ERROR,
      payload: err.message
    });
  }
};
