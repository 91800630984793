import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  addPost,
  getPost,
  editPost,
  setAlertForPost
} from '../../actions/post';
import { setAlert } from '../../actions/alert';
import { useParams } from 'react-router';
import PostForm from './PostForm';
import Spinner from '../layout/Spinner';

const PostEdit = ({
  getPost,
  editPost,
  setAlert,
  post: { posts, loading }
}) => {
  let { id } = useParams();

  const [formData, setFormData] = useState({
    contentForm: '',
    noAspForm: ''
  });

  const { contentForm, noAspForm } = formData;

  useEffect(() => {
    getPost(id);

    const { content, acf } = posts;
    console.log(content);
    if (content !== undefined) {
      const renderedNow = content.rendered;
      const noAspFormNow = acf.NO_ASP;
      console.log('renderedNow= ' + renderedNow);
      setFormData({
        contentForm: loading || !posts.content ? '' : renderedNow,
        noAspForm: loading || !posts.acf ? '' : noAspFormNow
      });
    }
  }, [loading]);

  // console.log(contentForm);
  // const { contentForm } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    const formDataSent = {
      content: contentForm,
      fields: {
        NO_ASP: noAspForm
      }
    };

    editPost(id, formDataSent);
    setAlert('Post edited succesfully', 'success');
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit Your Post</h1>
      <p className="lead">
        <i className="fas fa-user"></i> Let's get some information to make your
        profile stand out
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div>
          {/* <button type="button" onClick={() => load(data)}>
              Load Account
            </button> */}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="content"
            name="contentForm"
            value={contentForm}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Content</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="noAspForm"
            name="noAspForm"
            value={noAspForm}
            onChange={e => onChange(e)}
          />
          <small className="form-text">NO ASP</small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <a className="btn btn-light my-1" href="dashboard.html">
          Go Back
        </a>
      </form>
    </Fragment>
  );
};

PostEdit.propTypes = {
  addPost: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
  editPost: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  post: state.post
  // initialValues: state.post.id
});

export default connect(
  mapStateToProps,
  { addPost, getPost, editPost, setAlert }
)(PostEdit);
