import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_POSTS,
  POST_ERROR,
  DELETE_POST,
  ADD_POST,
  SET_ALERT,
  REMOVE_ALERT,
  GET_POST,
  UPDATE_POST,
  READ_IMAGE
} from './types';
import uuid from 'uuid';
import serverApi from '../utils/serverAPi';

// GET Posts
export const getPosts = () => async dispatch => {
  try {
    const res = await axios.get(
      `${serverApi}/wp-json/wp/v2/osf_property?per_page=100`
    );
    dispatch({
      type: GET_POSTS,
      payload: res.data,
      loading: false
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,

      payload: { msg: 'some error' },
      loading: false
    });
  }
};

// GET Post
export const getPost = id => async dispatch => {
  try {
    const res = await axios.get(
      `${serverApi}/wp-json/wp/v2/osf_property/${id}`
    );
    dispatch({
      type: GET_POST,

      payload: res.data,
      loading: false
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,

      payload: { msg: err.response.statusText, status: err.response.status },
      loading: false
    });
  }
};

// DELETE Post
export const deletePost = id => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.token}`
    }
  };
  try {
    const res = await axios.delete(
      `${serverApi}/wp-json/wp/v2/osf_property/${id}`,
      config
    );

    dispatch({
      type: DELETE_POST,
      payload: res.data.id,
      loading: false
    });

    // dispatch(getPosts());
    // dispatch(setAlert('Post Removed', 'success'));
  } catch (error) {
    dispatch({
      type: POST_ERROR,

      payload: { msg: 'error.data.code', status: 'error.data.status' },
      loading: false
    });
  }
};

// ADD Post
export const addPost = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  try {
    const res = await axios.post(
      `${serverApi}/wp-json/wp/v2/osf_property/`,
      formData,
      config
    );

    dispatch({
      type: ADD_POST,
      payload: res.data,
      loading: false
    });
    dispatch(getPosts());
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
      loading: false
    });
  }
};

// ALERT
export const setAlertForPost = (
  _msg,
  alertType,
  defaultTimeout = 5000,
  _id
) => dispatch => {
  const _id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: {
      _msg,
      alertType,
      _id
    }
  });

  setTimeout(
    () =>
      dispatch({
        type: REMOVE_ALERT
        // , payload: _id
      }),
    defaultTimeout
  );
};

// CHECK iF Image exists
export const checkImage = image => async dispatch => {
  const config = {
    headers: {
      'Access-control-allow-origin': '*'
    }
  };
  try {
    const res = await axios.get(image, config);
    console.log(res);
    dispatch({
      type: READ_IMAGE,
      payload: res.data,
      loading: false
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
      loading: false
    });
  }
};

// EDIT post
export const editPost = (id, formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.patch(
      `${serverApi}/wp-json/wp/v2/osf_property/${id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_POST,
      payload: res.data,
      loading: false
    });
    // dispatch(setAlert( 'Profile Updated' : 'Profile created', 'success'));
    // if (!edit) {
    //   history.push('/dashboard');
    // }
  } catch (err) {
    // loop thrrough the errors
    // const errors = err.message;
    // // if (errors !== undefined) {
    // //   errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    // // }
    // dispatch(setAlert(err.message, 'danger'));
    // dispatch({
    //   type: POST_ERROR,
    //   payload: err.message
    // });
  }
};
