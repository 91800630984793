import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { deletePost } from '../../actions/post';
import { setAlert } from '../../actions/alert';

const PostItem = ({
  auth,
  post: { id, content, excerpt, title, acf, date },
  deletePost,
  setAlert
}) => {
  const onDeleteItem = id => {
    setAlert('Post deleted', 'success');
    deletePost(id);
  };

  return (
    <div className="post bg-white p-1 my-1">
      <div>
        {/* {console.log(title.rendered)} */}
        <a href={`/post/${id}`}>
          <p>
            <b>ID:</b> {id}
          </p>
          <p>
            <b>acf.RAM:</b> {acf.RAM}
          </p>
          <p>
            <b>acf.NO_ASP:</b> {acf.NO_ASP}
          </p>
          <p>
            {acf.cantitate_ram ? (
              <span>
                <b>acf.cantitate_ram:</b> {acf.cantitate_ram}
              </span>
            ) : null}
          </p>

          {acf.NO_ASP ? (
            <img
              className="round-img"
              src={`https://articole-smart.eu/web-design-brainiacs.ro/duval/xml/8242-01-${acf.NO_ASP}-a.jpg`}
              style={{ width: '100px' }}
              alt=""
            />
          ) : null}

          <h4>{title.rendered}</h4>
        </a>
      </div>
      <div>
        <p className="my-1">{excerpt.rendered}</p>
        <p className="post-date">
          Posted on <Moment format="YYYY/MM/DD">{date}</Moment>
        </p>
        <button type="button" className="btn btn-light">
          <i className="fas fa-thumbs-up"></i>
          <span>4</span>
        </button>
        <button type="button" className="btn btn-light">
          <i className="fas fa-thumbs-down"></i>
        </button>
        <a href="post.html" className="btn btn-primary">
          Discussion <span className="comment-count">3</span>
        </a>
        <button
          onClick={e => onDeleteItem(id)}
          type="button"
          className="btn btn-danger"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deletePost: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deletePost, setAlert }
)(PostItem);
