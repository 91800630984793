// racfp
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';
import DashboardActions from './DashboardActions';

const Dashboard = ({
  getCurrentProfile,
  auth: { user_display_name },
  profile: { profile, loading }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, []);

  // const calculatoare = profile.map(pc => {
  //   return <li>{pc.id}</li>;
  // });
  const calculatoare = [];

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Dashboard</h1>

      <p className="lead">
        <i className="fas fa-user"></i> Welcome {user_display_name}
      </p>
      {profile !== [null] ? (
        <Fragment>
          {profile.map(pc => (
            <li key={pc.id}>
              {pc.id}
              <DashboardActions id={pc.id} />
            </li>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <p>No biens</p>
          <Link to="/create-profile" className="btn btn-primary my-1">
            Create profile
          </Link>
        </Fragment>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  // ptfr
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
