import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPost, getPosts, setAlertForPost } from '../../actions/post';
import { setAlert } from '../../actions/alert';

const PostForm = ({ addPost, getPosts, setAlertForPost, setAlert }) => {
  const [formData, setFormData] = useState({
    content: '',
    title: '',

    status: 'publish',
    NO_ASP_VALUE: ''
  });

  const { content, title, status, NO_ASP_VALUE } = formData;

  const onSubmit = e => {
    e.preventDefault();
    const formDataSent = {
      content,
      title,
      status,
      fields: {
        NO_ASP: NO_ASP_VALUE
      }
    };
    console.log(formDataSent);
    addPost(formDataSent);
    // setAlertForPost('Post created', 'success');

    getPosts();
    setAlert('Post created', 'success');
    setFormData({
      content: '',
      title: '',

      status: 'publish',
      NO_ASP_VALUE: ''
    });
  };

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  return (
    <Fragment>
      <h1 className="large text-primary">Create Your Post</h1>
      <p className="lead">
        <i className="fas fa-user"></i> Let's get some information to make your
        profile stand out
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Content"
            name="content"
            value={content}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Content</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
          />
          <small className="form-text">title</small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="NO_ASP"
            name="NO_ASP_VALUE"
            value={NO_ASP_VALUE}
            onChange={e => onChange(e)}
          />
          <small className="form-text">NO_ASP</small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <a className="btn btn-light my-1" href="dashboard.html">
          Go Back
        </a>
      </form>
    </Fragment>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  setAlertForPost: PropTypes.func.isRequired
};

export default connect(
  null,
  { addPost, getPosts, setAlertForPost, setAlert }
)(PostForm);
